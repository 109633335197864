export const environment = {
  name: 'heroes',
  production: true,
  enablePendo: false,
  enableDatadog: false,
  enableServiceWorker: true,
  enableGoogleAnalytics: false,
  enableFullStory: false,
  applicationUrl: 'https://stg.heroes.mims.rewaatech.com',
  webSocketUrl:
    'wss://g344rwu8ze.execute-api.us-east-1.amazonaws.com/stgheroes',
  fullViewClientId: '',
  sallaAppClientId: 'de908acb69e764416b9f3ca0db8a6cd7',
  zidAppClientId: '350',
  userPoolId: 'us-east-1_8iVM0BFPm',
  clientId: '3pd9mqu5outudk7k30uhj45n9n',
  bonatServiceToken: '',
  bonatAppClientId: 'q9lbrf2oaogk0ujokrcqk2vpm',
  bonatRedirectUrl: 'https://stg-api.bonat.io/rewaa/install',
  cognitoAuthUrl: 'https://rewaa.auth.ap-south-1.amazoncognito.com/login',
  s3InventoryBucket:
    'https://s3.amazonaws.com/stg-heroes-mims-service-any-item-image/inventory/products/',
  defaultImage: 'default.png',
  enableHttp: true,
  enableIntercom: false,
  tapPublicKey: 'pk_test_jMgIW1ilDhXYSNPQ6BKob8aV',
  s3Bucket:
    'https://s3.ap-south-1.amazonaws.com/stg.heroes.mims.rewaatech.com/',
  offline_intervals: { product: 2160000, customer: 2160000 },
  offline_config: {
    invoice_limit: { android: 250, others: 300 },
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDpqm3hlLPwJ5mZmyX_GuGfth1lRWB1fHo',
    authDomain: 'rewaa-test.firebaseapp.com',
    projectId: 'rewaa-test',
    storageBucket: 'rewaa-test.appspot.com',
    messagingSenderId: '181952186125',
    appId: '1:181952186125:web:d9530938a18589a82f9244',
    measurementId: 'G-6XEQD0RRJV',
  },
  enableUserGuiding: false,
  enableUserPilot: false,
  launchDarklyClientSideId: '63638a3e476fbc3421302aee',
  mockLaunchDarkly: false,
  enableAutomationTestFF: false,
  automationTestFFAPIKey: '123',
  pluginUrl: 'http://localhost:5000',
  accountingApiPath: '/api/accounting',
  expenseApiPath: '/api/expense-service',
  customFieldApiPath: '/api/custom-field-service',
  intercomAppId: 'sg0ry903',
  enableFirestore: true,
  allowAliasedEmails: true,
  reportDataLimit: 10000,
  reportingApiUrl: 'https://reporting.qa.heroes.rewaatech.com/cubejs-api/v1',
  appVersion: require('../../package.json').version,
  nearpayEnvironment: 'sandbox',
  launchDarklyBaseUrl: undefined,
};
